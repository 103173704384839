import React from "react";
import * as styles from "../styles/navbar.module.css";
import bird from "../assets/bird.png";
import HeaderHamburger from "../assets/hamburger.png";
import HamburgerMenu from "react-hamburger-menu";
import Group from "../assets/group.png";
import { messages } from "./locales"

const Header2 = ({ showModal, setModal, open, handleClick, language }) => {
  let lang = language ? language : "en";
  return (
    <section className={styles.navbar}>
      <div className={`${styles.navCont} ${styles.navFlex}`}>
        <div className={styles.navbarWrapper}>
          <img
            src={bird}
            alt="Logo of Everyone is good at something"
            className={styles.logoContainer}
          />
          <h2 className={styles.mediah2}>
            e <div className={styles.headerDot}></div> g{" "}
            <div className={styles.headerDot}></div> s
          </h2>
          <h2 className={styles.headerh2}>
            {messages[lang].everyone} <div className={styles.headerDot}></div>{" "}
            {messages[lang].is} <div className={styles.headerDot}></div>{" "}
            {messages[lang].good} <div className={styles.headerDot}></div>{" "}
            {messages[lang].at} <div className={styles.headerDot}></div>{" "}
            {messages[lang].something}
          </h2>
        </div>
        {!open && (
          <ul>
            <li>
              <img
                alt="Menu icon"
                className={styles.hamburger}
                src={HeaderHamburger}
                onClick={() => {
                  setModal(!setModal);
                  handleClick(!open);
                }}
              />
            </li>
          </ul>
        )}

        {open && (
          <ul>
            <li>
              <img
                className={styles.hamburger}
                alt="Menu icon"
                src={Group}
                onClick={() => {
                  setModal(!setModal);
                  handleClick(!open);
                }}
              />
            </li>
          </ul>
        )}

        <div className={styles.hamMenu}>
          <ul>
            <li>
              <HamburgerMenu
                isOpen={open}
                menuClicked={() => {
                  handleClick();
                  setModal(!setModal);
                }}
                width={30}
                height={25}
                strokeWidth={1}
                rotate={0}
                color="black"
                borderRadius={0}
                animationDuration={0.5}
              />
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Header2;
